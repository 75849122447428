
import { DotLoading } from "antd-mobile";
import { lazy, useEffect, useState } from "react";
import Loading from "./modules/h5/public/Loading";

const AppMod = lazy(()=>import(`../src/modules/${process.env.REACT_APP_MOD}/index.tsx`));

export default () => {


  console.log("AppMod=>",AppMod);


  return (
    <>
      <AppMod/>
    </>
  )

};
